import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ReactTooltip from "react-tooltip";


import { infoToast } from ".";
import { PasswordEyeIcon, EyeIconNoCross, InfoIcon } from "./svg";
import "../utilities/styles/textFormatter.css";
// import { InfoTooltip } from "./ToolTip";

const InputLabel = ({ name, label }) => (
  <label htmlFor={name} className="text-base font-semibold text-brandGrey mb-1">
    {label}
  </label>
);

export const InputGroup = ({
  label,
  name,
  placeholder,
  onChange,
  value,
  type,
  errorMessage,
  alphabetsOnly,
  numberSpacing,
  disabled,
  classNames,
  Icon,
  autoFocus,
  showPercentage = false,
}) => {
  return (
    <div className="flex flex-col mb-4">
      <InputLabel name={name} label={label} />

      <div className="relative">
        <input
          type={type || "text"}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e, alphabetsOnly, numberSpacing)}
          className={`appInput w-full mb-0 ${classNames}`}
          style={{ borderColor: "#B9B9BB" }}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        {Icon && (
          <div className="absolute top-1/2 transform -translate-y-1/2 right-1 pr-3 pl-2 z-10 bg-white">
            <Icon />
          </div>
        )}
        {showPercentage && <h1 className="absolute right-2 bottom-2">%</h1>}
      </div>

      <p className="text-red-600 text-base">{errorMessage}</p>
    </div>
  );
};

export const SelectDropdown = ({
  label,
  name,
  value,
  onChange,
  errorMessage,
  optionsArray,
  placeholder,
  classNames,
  style,
  disabled,
  placeholderDropdown = true,
}) => {
  return (
    <div className="flex flex-col mb-4">
      <InputLabel name={name} label={label} />

      <select
        name={name}
        className={`appInput appMainSelectInput mb-0 ${classNames}`}
        value={value}
        onChange={(e) => onChange(e)}
        style={{
          borderColor: "#B9B9BB",
          ...style,
        }}
        disabled={disabled}
      >
        {placeholderDropdown ? (
          <option disabled={false} value="">
            {placeholder}
          </option>
        ) : (
          <option value="" disabled selected hidden>
            {placeholder}
          </option>
        )}

        {optionsArray?.map(({ name, isoCode }, index) => (
          <option key={index} value={isoCode ? isoCode : name}>
            {name}
          </option>
        ))}
      </select>

      <p className="text-red-600 text-base">{errorMessage}</p>
    </div>
  );
};

export const PhoneNumberInput = ({
  label = "Phone Number",
  errorMessage,
  name,
  value,
  onChange,
  disabled,
  infoIcon = false,
}) => {
  useEffect(() => {
    if (document.querySelector(".PhoneInputInput")) {
      document.querySelector(".PhoneInputInput").className =
        "appInput pl-12 370px:pl-14";
    }
  }, []);
  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-center space-x-2">
        <InputLabel name={name} label={label} />
        {infoIcon && (
          <>
            <InfoIcon
              data-tip="We will send you verification code to this mobile number"
              className="cursor-pointer"
            />
            <ReactTooltip
              className="w-1/2 500px:w-1/6"
              place="top"
              clickable={true}
              delayHide={100}
            />
          </>
        )}
      </div>

      <PhoneInput
        defaultCountry="US"
        international
        countryCallingCodeEditable={false}
        name={name}
        value={value}
        onChange={(value) => onChange(value)}
        className="p-2 370px:p-4 mb-0 phoneNumberInputGroup relative"
        disabled={disabled}
      />

      <p className="text-red-600 text-base">{errorMessage}</p>
    </div>
  );
};

export const InputGroupEye = ({
  label,
  name,
  value,
  onChange,
  errorMessage,
  placeholder,
  refs,
  onKeyPress,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div className="flex flex-col mb-4">
      <InputLabel name={name} label={label} />

      <div className="relative">
        <input
          value={value}
          onChange={onChange}
          className="appInput w-full mb-0"
          type={passwordShown ? "text" : "password"}
          name={name}
          style={{ borderColor: "#B9B9BB" }}
          placeholder={placeholder}
          ref={refs}
          onKeyPress={onKeyPress}
        />
        <div
          onClick={() => setPasswordShown(!passwordShown)}
          className=" absolute top-1/2 transform -translate-y-full right-5 cursor-pointer activeState buttonHover"
        >
          {!passwordShown ? (
            <PasswordEyeIcon className="absolute w-5 -top-2 right-0 -left-2" />
          ) : (
            <EyeIconNoCross className="absolute w-5 -top-5 right-0 -left-2" />
          )}
        </div>
      </div>

      <p className="text-red-600 text-base">{errorMessage}</p>
    </div>
  );
};

export const TextArea = React.forwardRef(
  (
    {
      placeholder,
      name,
      label,
      onChange,
      value,
      errorMessage,
      classNames,
      col = 80,
      rows = 7,
      setValue,
      disabled = false,
      limit = 999999,
    },
    ref
  ) => {
    const [blockEvent, setBlockEvent] = useState(false);
    let availableCharacterLength = limit - value.trim().length;
    return (
      <div className="flex flex-col mb-4">
        <InputLabel name={name} label={label} />
        <textarea
          ref={ref}
          placeholder={placeholder}
          cols={col}
          rows={rows}
          name={name}
          value={value}
          onPaste={(e) => {
            setBlockEvent(true);
            setValue(
              value +
                e.clipboardData
                  .getData("Text")
                  .substring(0, availableCharacterLength)
            );
            if (
              e.clipboardData.getData("Text").length > availableCharacterLength
            ) {
              infoToast("maximum limit exceeded");
              setTimeout(() => {
                toast.clearWaitingQueue();
              }, 2000);
            }
          }}
          onChange={(e) => {
            if (!blockEvent) {
              if (e.target.value.trim().length <= limit) {
                onChange(e);
              } else {
                infoToast("maximum limit exceeded");
                setTimeout(() => {
                  toast.clearWaitingQueue();
                }, 2000);
              }
            } else {
              setBlockEvent(false);
            }
          }}
          className={`appInput w-full  ${classNames}`}
          style={{ borderColor: "#B9B9BB" }}
          disabled={disabled}
        />
        <p className="text-red-600 text-base">{errorMessage}</p>
      </div>
    );
  }
);

export const CheckBoxGroup = ({ onClick, state, name, label }) => (
  <div className="checkbox-group" onClick={() => onClick()}>
    <input
      type="checkbox"
      name={name}
      checked={state}
      onChange={() => onClick()}
    />
    <label
      htmlFor={name}
      className={` text-base cursor-pointer ${
        state ? "font-semibold" : "font-medium"
      }`}
    >
      {label}
    </label>
  </div>
);

export const FormatEditor = ({
  name,
  tab,
  editorState,
  state,
  setEditorState,
  errorMessage,
  setFormError,
  editorHeight = "300px",
  limit = 1000,
  multipleEditors = false,
  index,
  questions,
  setQuestions,
  questionsformError,
  setQuestionsFormError,
  uploadImageCallBack,
  options = ["inline", "list", "textAlign"],
}) => {
  const MAX_LENGTH = limit;
  const [focus, setFocus] = useState(false); // to focus the react draft editor text field
  const [enterCount, setEnterCount] = useState(0);

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const removeSelection = () => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(
        editorState.getCurrentContent(),
        selection,
        "forward"
      );
      const tempEditorState = EditorState.push(
        editorState,
        newContent,
        "remove-range"
      );
      setEditorState(tempEditorState);
      return tempEditorState;
    }
    return editorState;
  };

  const addPastedContent = (input, editorState) => {
    const inputLength = editorState
      .getCurrentContent()
      .getPlainText()
      .replace(/\s/g, "")
      .trim().length;
    let remainingLength = MAX_LENGTH - inputLength;

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength)
    );
    setEditorState(
      EditorState.push(editorState, newContent, "insert-characters")
    );
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleMultipleEditorChange = (editorState) => {
    let newArr = [...state];
    newArr[index] = editorState;
    setEditorState(newArr);

    let newQuestionArr = [...questions];
    newQuestionArr[index] = {
      question: convertToRaw(newArr[index].getCurrentContent()),
      subtitle: questions[index]?.subtitle,
    };

    setQuestions(newQuestionArr);
  };

  const handleBeforeInput = () => {
    if (questions) {
      let error = questionsformError?.questions;
      delete error[name];
      setQuestionsFormError({
        ...questionsformError,
        ...error,
      });
    }

    setFormError({});
    const plainText = editorState.getCurrentContent().getPlainText("");
    const text = plainText.replace(/\s/g, "").trim();
    const selectedTextLength = getLengthOfSelectedText();

    if (text.length - selectedTextLength > MAX_LENGTH - 1) {
      infoToast("Maximum limit exceeded");
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 2000);

      return "handled";
    }
    return "not-handled";
  };

  const handlePastedText = (pastedText) => {
    setFormError({});
    const plainText = editorState.getCurrentContent().getPlainText("");
    const text = plainText.replace(/\s/g, "").trim();
    const selectedTextLength = getLengthOfSelectedText();
    const pastedTextWithoutSpace = pastedText.replace(/\s/g, "").trim();
    if (
      text.length + pastedTextWithoutSpace.length - selectedTextLength >
      MAX_LENGTH
    ) {
      const selection = editorState.getSelection();
      const isCollapsed = selection.isCollapsed();
      const tempEditorState = !isCollapsed ? removeSelection() : editorState;
      addPastedContent(pastedTextWithoutSpace, tempEditorState);
      infoToast("Maximum limit exceeded");
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 2000);

      return "handled";
    }
    return "not-handled";
  };

  const handleReturn = (e) => {
    if (e.keyCode === 13) {
      setEnterCount(enterCount + 1);
    }
  };

  return (
    <>
      <Editor
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        editorStyle={{
          height: editorHeight,
          overflowY:
            focus &&
            (enterCount > 2 ||
              editorState
                .getCurrentContent()
                .getPlainText("")
                .replace(/\s/g, "")
                .trim().length > 300)
              ? "scroll"
              : "hidden",
        }}
        placeholder={
          ["unordered-list-item", "ordered-list-item"].includes(
            convertToRaw(editorState.getCurrentContent()).blocks[0].type
          )
            ? ""
            : tab === "task"
            ? "Start typing exercise"
            : tab === "story"
            ? "Enter your story"
            : tab === "question"
            ? "Enter your question"
            : tab === "notes" && "Enter your notes"
        }
        toolbarClassName="toolbar-class"
        editorClassName={`${
          focus ? "editorafter-class" : "editorbefore-class"
        }`}
        editorState={editorState}
        onEditorStateChange={
          multipleEditors ? handleMultipleEditorChange : handleEditorChange
        }
        toolbar={{
          options: options,
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
          },
          image: {
            uploadEnabled: true,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
          },
          link: {
            linkCallback: (params) => ({ ...params }),
          },
        }}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        handleReturn={(e) => handleReturn(e)}
      />
      <p className="text-red-600 text-base">{errorMessage}</p>
    </>
  );
};


