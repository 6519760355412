import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { memberPurchaseMinutes } from "../../../AppRedux/actions/MemberDashboard";
import { getPurchaseMinutes } from "../../../AppRedux/actions/MemberDashboard";
import ModalOuter from "./ModalOuter";
import {
  CardButton,
  errorToast,
  Scaleloader,
  infoToast,
  CancelButton,
} from "../../../components/utilities";
import // PremiumSubscriptionDiamondIcon,
// NormalSubscriptionDiamondIcon,
"../../utilities/svg";

export default function Modal({ state, setState }) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [paymentData, setPaymentData] = useState();
  const [paymentId, setPaymentId] = useState("");

  const {
    purchaseMentorMinutesBegin,
    purchaseMentorMinutesFailureData,
    purchaseMentorMinutesSuccessData,
    getPurchaseMinutesPlanBegin,
    getPurchaseMinutesPlanSuccessData,
  } = useSelector(({ memberDashboard }) => memberDashboard);

  useEffect(() => {
    dispatch(getPurchaseMinutes());
  }, [dispatch]);

  useEffect(() => {
    if (getPurchaseMinutesPlanSuccessData) {
      setPaymentData(getPurchaseMinutesPlanSuccessData);
    }
  }, [getPurchaseMinutesPlanSuccessData]);

  useEffect(() => {
    if (purchaseMentorMinutesSuccessData) {
      window.location.href = purchaseMentorMinutesSuccessData.data.url;
    }
    if (purchaseMentorMinutesFailureData) {
      errorToast(purchaseMentorMinutesFailureData.data.message);
    }
  }, [purchaseMentorMinutesSuccessData, purchaseMentorMinutesFailureData]);

  const onValueChange = (e) => {
    setSelectedOption(e.target.name);
    setPaymentId(e.target.value);
  };

  const onPurchaseButtonClick = () => {
    if (selectedOption === "") {
      infoToast("Please select a plan to continue");
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 2000);
    } else {
      const data = {
        priceId: paymentId,
      };
      dispatch(memberPurchaseMinutes(data));
    }
  };

  return (
    <ModalOuter
      state={state}
      setState={setState}
      classNames="px-5 py-8 animate-modalInitial min-w-200px 500px:min-w-70%"
    >
      <div style={{ minHeight: "380px" }}>
        {!getPurchaseMinutesPlanBegin && paymentData ? (
          <>
            <h1 className=" text-3xl font-extrabold text-brandBlue mb-2">
              Choose your plan
            </h1>
            <p>
              Coaching minutes can be scheduled a a minimum of 15 minute intervals
            </p>
            {paymentData?.map(
              ({ name, paymentId, amount, minutes, description }, index) => (
                <label key={index}>
                  <div
                    className={`p-4 mt-4 relative rounded-md activeState dashboardListHover border border-brandBlue `}
                    style={{
                      border: "7px solid #007DA5",
                      boxShadow: "0px 4px 14px 2px rgba(0, 0, 0, 0.02)",
                    }}
                    onClick={(e) => onValueChange(e)}
                  >
                    <input
                      name={name}
                      className="absolute right-1 top-1/2 -translate-y-1/2"
                      type="radio"
                      value={paymentId}
                      checked={selectedOption === name}
                      onChange={(e) => onValueChange(e)}
                    />
                    {/* <p
                      className=" font-bold text-sm mt-1"
                      style={{ color: "#B3B3B3" }}
                    >
                      {name.toUpperCase()}
                    </p> */}
                    <p
                      className="font-extrabold text-xl my-2 text-brandDarkBlue  "
                      style={{ textShadow: "0.4px 0 #000" }}
                    >
                      {minutes} mins/${amount}
                    </p>
                    {/* <p
                      className=" text-xs font-semibold"
                      style={{ color: "#9E9E9E" }}
                    >
                      {description}
                    </p> */}
                  </div>
                </label>
              )
            )}
            <div className="flex justify-end mt-10 gap-4">
              <CancelButton setState={setState} />
              <CardButton
                onClick={() => onPurchaseButtonClick()}
                text="Continue payment"
                loader={purchaseMentorMinutesBegin}
                classNames="bg-brandBlue py-2 font-normal text-base flex "
              />
            </div>
          </>
        ) : (
          <Scaleloader />
        )}
      </div>
    </ModalOuter>
  );
}
